export enum Role {
  ANALYST = "analyst",
  CUSTOMER_SUCCESS = "cyb-customer-success",
  OPERATIONS = "cyb-operations",
  TENANT_WORKFORCE = "tnt-workforce",
  CYBRILLA_ADMIN = "cyb-admin",
  TENANT_OPERATIONS = "tnt-operations",
  TENANT_ROOT = "tnt-root",
  TENANT_PARTNER = "tnt-partner",
  THIRDEYE_MANAGER = "manager",
  THIRDEYE_OPERATIONS = "operations",
  THIRDEYE_SQLLAB = "sqllab",

  // amc
  OPERATIONS_ANALYST = "cyb-operations-analyst",
  TENANT_OPERATIONS_ANALYST = "tnt-operations-analyst",
  TENANT_BUSINESS_ANALYST = "tnt-business-analyst",
}

export type Roles = Role[];

export type UserInfoResponse = {
  sub: string;
  tenants: string[];
  email_verified: boolean;
  roles: Roles;
  given_name: string;
  family_name: string;
  email: string;
  type: "internal" | "workforce";
  fp_identifier?: string;
};

export const RoleLabelMap: Partial<Record<Role, string>> = {
  analyst: "thirdeye analyst",
  operations: "thirdeye operations",
  "cyb-customer-success": "customer success",
  "cyb-admin": "cybrilla admin",
  "cyb-operations": "operations",
  "tnt-workforce": "workforce",
  "tnt-operations": "tenant operations",
  "tnt-root": "tenant root",
  "tnt-partner": "tenant partner",

  // amc
  "tnt-operations-analyst": "tenant operations analyst",
  "cyb-operations-analyst": "operations analyst",
  "tnt-business-analyst": "tenant business analyst",
};

export const TenantPartnerRoles: Role[] = [Role.TENANT_PARTNER];

export const TenantRoles: Role[] = [
  Role.ANALYST,
  Role.THIRDEYE_OPERATIONS,
  Role.TENANT_WORKFORCE,
  Role.TENANT_OPERATIONS,
  Role.TENANT_OPERATIONS_ANALYST,
];

export const TenantRootRoles: Role[] = [Role.TENANT_ROOT];

export const InternalRoles: Role[] = [
  Role.ANALYST,
  Role.THIRDEYE_OPERATIONS,
  Role.CUSTOMER_SUCCESS,
  Role.OPERATIONS,
  Role.CYBRILLA_ADMIN,
  Role.OPERATIONS_ANALYST,
];
