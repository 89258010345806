import { Role } from "../context/userinfo/types";

export const RouteAccessMap: { [key: string]: Role[] } = {
  /*************************** FP Console Routes ***************************/

  "/fp-console/order-failure-code-list": [Role.OPERATIONS, Role.CYBRILLA_ADMIN],
  "/fp-console/order-failure-code": [Role.OPERATIONS, Role.CYBRILLA_ADMIN],
  "/fp-console/action-center": [Role.OPERATIONS, Role.CYBRILLA_ADMIN],
  "/fp-console": [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.CUSTOMER_SUCCESS],
  "/fp-console/fp-account-details/tenants": [Role.CYBRILLA_ADMIN],
  "/fp-console/fp-account-details/tenants/new": [Role.CYBRILLA_ADMIN],
  "/fp-console/fp-account-details/user-management": [Role.CYBRILLA_ADMIN],
  "/fp-console/fp-account-details/user-management/new": [Role.CYBRILLA_ADMIN],
  "/fp-console/fp-account-details/user-management/root/new": [Role.CYBRILLA_ADMIN],
  "/fp-console/fp-account-details/user-management/:id": [Role.CYBRILLA_ADMIN],
  "/fp-console/pincodes": [Role.CYBRILLA_ADMIN, Role.CUSTOMER_SUCCESS],
  "/fp-console/ifsc-codes": [Role.CYBRILLA_ADMIN, Role.CUSTOMER_SUCCESS],

  /*************************** Operations routes ***************************/
  "/operations/order-operations": [
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_OPERATIONS,
  ],
  "/operations/order-operations/files/regenerate": [
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
  ],
  "/operations/order-operations/files": [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
  "/operations/aging-orders": [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
  "/operations/order-operations/batch-files": [
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
  ],
  "/operations/transactions": [
    Role.OPERATIONS,
    Role.TENANT_WORKFORCE,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
  ],
  "/operations/transfers": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/operations/transfers/:transfer_reference": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/folios": [
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/folios/:folio_number": [
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/operations/fund-schemes": [
    Role.OPERATIONS,
    Role.CUSTOMER_SUCCESS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/operations/fund-schemes/scheme-details/:scheme_isin": [
    Role.OPERATIONS,
    Role.CUSTOMER_SUCCESS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/operations/order-operations/file-operations": [
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
  ],

  /*************************** Developer Routes ***************************/
  "/developers/api-logs": [Role.CUSTOMER_SUCCESS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
  "/developers/api-logs/:id": [Role.CUSTOMER_SUCCESS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
  "/developers/events": [Role.CUSTOMER_SUCCESS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],

  /*************************** Partner Routes ***************************/
  "/partners": [Role.CUSTOMER_SUCCESS, Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
  "/partners/new": [Role.CYBRILLA_ADMIN, Role.CUSTOMER_SUCCESS],
  "/partners/:partnerId": [Role.CYBRILLA_ADMIN, Role.TENANT_ROOT, Role.CUSTOMER_SUCCESS],

  /*************************** FP Account Routes ***************************/
  "/fp-account/verifications": [Role.CUSTOMER_SUCCESS, Role.OPERATIONS, Role.CYBRILLA_ADMIN],
  "/fp-account/payments_gateway": [Role.CUSTOMER_SUCCESS, Role.OPERATIONS, Role.CYBRILLA_ADMIN],
  "/fp-account/mf_services": [Role.OPERATIONS, Role.CUSTOMER_SUCCESS, Role.CYBRILLA_ADMIN],
  "/fp-account/amcs": [Role.OPERATIONS, Role.CUSTOMER_SUCCESS, Role.CYBRILLA_ADMIN],
  "/fp-account/kyc-services": [Role.OPERATIONS, Role.CUSTOMER_SUCCESS, Role.CYBRILLA_ADMIN],
  "/fp-account/advisory": [Role.OPERATIONS, Role.CUSTOMER_SUCCESS, Role.CYBRILLA_ADMIN],
  "/fp-account/user_management": [Role.CYBRILLA_ADMIN, Role.TENANT_ROOT, Role.CUSTOMER_SUCCESS],
  "/fp-account/user-management/new": [Role.CYBRILLA_ADMIN],
  "/fp-account/user-management/:id": [Role.CYBRILLA_ADMIN],
  "/fp-account/bi-tool": [Role.CYBRILLA_ADMIN, Role.OPERATIONS, Role.CUSTOMER_SUCCESS],
  "/fp-account/scheduled-queries": [
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.OPERATIONS,
    Role.TENANT_WORKFORCE,
  ],
  "/fp-account/scheduled-queries/scheduled-query-runs/:scheduled_query_id": [
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.OPERATIONS,
    Role.TENANT_WORKFORCE,
  ],
  "/fp-account/notification-webhooks": [Role.CYBRILLA_ADMIN, Role.TENANT_ROOT, Role.OPERATIONS],
  "/fp-account/notification-webhooks/:webhook_id": [
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.OPERATIONS,
  ],
  "/fp-account/fp-assist": [Role.CYBRILLA_ADMIN],
  "/fp-account/configuration-status": [
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.OPERATIONS,
    Role.CUSTOMER_SUCCESS,
  ],

  /*************************** Partner Routes ***************************/
  "/partner/portfolio": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/assisted-checkout": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/onboarding": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/assisted-withdraw": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/assisted-profile-details": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/assisted-onboarding": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/assisted-order-history": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/assisted-plans-list": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/folios": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/support": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/plan-update": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],
  "/partner/status/mandate": [Role.CYBRILLA_ADMIN, Role.TENANT_PARTNER],

  /*************************** Order Routes ***************************/
  "/orders/overview": [
    Role.CYBRILLA_ADMIN,
    Role.OPERATIONS_ANALYST,
    Role.TENANT_OPERATIONS_ANALYST,
  ],

  "/orders/purchases": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/purchases/:purchaseId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/redemptions": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/redemptions/:redemptionId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/switches": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/switches/:switchId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/purchase-plans": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/purchase-plans/:purchasePlanId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/redemption-plans": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/redemption-plans/:redemptionPlanId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/switch-plans": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/switch-plans/:switchPlanId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/orders/orders-trend": [Role.CYBRILLA_ADMIN, Role.OPERATIONS, Role.TENANT_BUSINESS_ANALYST],

  /*************************** Payment Routes ***************************/
  "/payments": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/payments/:paymentId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/mandates": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/mandates/:mandateId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],

  /*************************** Investors Routes ***************************/

  "/investors/investment_accounts": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/investment_accounts/:mfiaId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/investors-overview": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/investors-overview/v2/:investorProfileId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/investors-overview/v1/:investorId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/bank-account-verifications": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/non-individual-investors": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/non-individual-investors/:nonIndividualInvestorProfileId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],

  "/investors/kyc_requests": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
  "/investors/kyc_requests/:kycRequestId": [
    Role.CUSTOMER_SUCCESS,
    Role.OPERATIONS,
    Role.CYBRILLA_ADMIN,
    Role.TENANT_ROOT,
    Role.TENANT_WORKFORCE,
  ],
};

// Get the list of roles for a given menu by checking all the submenu
export const getMenuRoles = (checkPath: string, matchExact = false) => {
  const expression = new RegExp(matchExact ? `^${checkPath}$` : `^${checkPath}`);

  const validRoles = Object.entries(RouteAccessMap).reduce((prev, [path, roles]) => {
    if (expression.test(path)) {
      roles.forEach((role) => prev.add(role));
    }
    return prev;
  }, new Set<Role>());

  return Array.from(validRoles);
};
