import { lazy } from "react";

//Lazy workflows
const LazyManageOrderFailureCodes = lazy(
  () => import("./components/workflow/ManageOrderFailureCodes")
);
const LazyManageOrderOperations = lazy(() => import("./components/workflow/ManageOrderOperations"));
const LazyManageConfigurations = lazy(() => import("./components/workflow/ManageConfigurations"));
const LazyManageConsolidatedActions = lazy(
  () => import("./components/workflow/ConsolidatedActions")
);
const LazyManagePaymentsGateway = lazy(() => import("./components/workflow/ManagePaymentsGateway"));

//Workflows
export {
  LazyManageConfigurations as ManageConfigurations,
  LazyManageConsolidatedActions as ManageConsolidatedActions,
  LazyManageOrderFailureCodes as ManageOrderFailureCodes,
  LazyManageOrderOperations as ManageOrderOperations,
  LazyManagePaymentsGateway as ManagePaymentsGateway,
};

//Lazy Functional
const LazyListPaymentTransfers = lazy(
  () => import("./components/functional/PaymentTransfers/ListPayments/ListPayment")
);
const LazyShowTransferDetails = lazy(
  () => import("./components/functional/PaymentTransfers/ShowTransfers/ViewTransferDetails")
);
const LazyRegenerateFatcaAnnexure = lazy(
  () =>
    import(
      "./components/functional/OrderOperations/RegenerateFatcaAnnexure/RegenerateFatcaAnnexure"
    )
);
const LazyListFiles = lazy(() => import("./components/functional/OrderOperations/Files/ListFiles"));
const LazyListAgingOrders = lazy(
  () => import("./components/functional/AgingOrders/ListAgingOrders")
);
const LazyListApiLogs = lazy(
  () => import("./components/functional/ApiLogs/ListApiLogs/ListApiLogs")
);
const LazyViewApiLogDetaills = lazy(
  () => import("./components/functional/ApiLogs/ViewApiLogDetaills/ViewApiLogDetails")
);
const LazyAmcConfigurationService = lazy(
  () => import("./components/functional/FpAccount/AmcConfigurationService")
);
const LazyKYCService = lazy(() => import("./components/functional/FpAccount/KycService"));
const LazyOrderConfigurationService = lazy(
  () => import("./components/functional/FpAccount/OrderConfigurationService")
);
const LazyVerificationService = lazy(
  () => import("./components/functional/FpAccount/VerificationsService")
);
const LazyAdvisoryService = lazy(
  () => import("./components/functional/FpAccount/AdvisoryService/AdvisoryService")
);
const LazyFundSchemesListing = lazy(
  () => import("./components/functional/FpAccount/FundSchemes/ListSchemes/FundSchemesListing")
);
const LazyBITool = lazy(() => import("./components/functional/FpAccount/BITool"));
const LazyAddTenant = lazy(
  () => import("./components/functional/TenantManagement/AddTenant/AddTenant")
);

const LazyTenantsListing = lazy(
  () => import("./components/functional/TenantManagement/TenantsListing")
);

const LazyTransactionsListing = lazy(
  () => import("./components/functional/Transactions/TransactionsListing")
);

const LazyInternalUsersManagement = lazy(
  () =>
    import("./components/functional/UserManagement/InternalUsers/ListUsers/InternalUsersListing")
);

const LazyManageUser = lazy(
  () => import("./components/functional/UserManagement/ManageUser/ManageUser")
);
const LazyTenantUsersManagement = lazy(
  () => import("./components/functional/UserManagement/TenantUsers/ListUsers/UsersListing")
);

const LazyPincodes = lazy(() => import("./components/functional/MasterData/Pincodes"));

const LazyIFSCCodes = lazy(() => import("./components/functional/MasterData/IFSCCodes"));

const LazyScheduledQueries = lazy(
  () =>
    import(
      "./components/functional/FpAccount/ScheduledQueries/ListScheduledQueries/ListScheduledQueries"
    )
);

const LazyScheduledQueryRun = lazy(
  () =>
    import(
      "./components/functional/FpAccount/ScheduledQueries/ListScheduledQueryRuns/ListScheduledQueriesRuns"
    )
);

const LazyEditNotificationWebhooks = lazy(
  () =>
    import(
      "./components/functional/FpAccount/NotificationWebhooks/Update/UpdateNotificationWebhook"
    )
);

const LazyNotificationWebhooks = lazy(
  () => import("./components/functional/FpAccount/NotificationWebhooks/NotificationWebhooks")
);

const LazyPartnersListing = lazy(() => import("./components/functional/Partners/PartnersListing"));
const LazyPartnerAddition = lazy(() => import("./components/functional/Partners/PartnerAddition"));
const LazyPartnerDetails = lazy(
  () => import("./components/functional/Partners/Details/PartnersDetails")
);

const LazyFpAssist = lazy(() => import("./components/functional/FpAccount/FpAssist/FpAssist"));

const LazyBatchFilesListing = lazy(
  () => import("./components/functional/OrderOperations/BatchFiles/BatchFilesListing")
);

const LazyFolioListing = lazy(() => import("./components/functional/Folios/FolioListing"));
const LazyFolioDetails = lazy(
  () => import("./components/functional/Folios/FolioDetails/FolioDetails")
);

const LazySchemeDetails = lazy(
  () => import("./components/functional/FpAccount/FundSchemes/SchemeDetails/SchemeDetails")
);

const LazyEventsListing = lazy(() => import("./components/functional/Events/EventsListing"));

const LazyPurchaseListing = lazy(
  () => import("./components/functional/Orders/Purchases/PurchaseListing/PurchaseListing")
);
const LazyPurchaseDetails = lazy(
  () => import("./components/functional/Orders/Purchases/PurchaseDetails/PurchaseDetails")
);
const LazyRedemptionListing = lazy(
  () => import("./components/functional/Orders/Redemptions/RedemptionListing/RedemptionListing")
);
const LazyRedemptionDetails = lazy(
  () => import("./components/functional/Orders/Redemptions/RedemptionDetails/RedemptionDetails")
);
const LazySwitchListing = lazy(
  () => import("./components/functional/Orders/Switches/SwitchListing/SwitchListing")
);
const LazySwitchDetails = lazy(
  () => import("./components/functional/Orders/Switches/SwitchDetails/SwitchDetails")
);
const LazyPurchasePlanListing = lazy(
  () =>
    import("./components/functional/Orders/PurchasePlan/PurchasePlanListing/PurchasePlanListing")
);
const LazyPurchasePlanDetails = lazy(
  () =>
    import("./components/functional/Orders/PurchasePlan/PurchasePlanDetails/PurchasePlanDetails")
);
const LazyRedemptionPlanListing = lazy(
  () =>
    import(
      "./components/functional/Orders/RedemptionPlan/RedemptionPlanListing/RedemptionPlanListing"
    )
);
const LazyRedemptionPlanDetails = lazy(
  () =>
    import(
      "./components/functional/Orders/RedemptionPlan/RedemptionPlanDetails/RedemptionPlanDetails"
    )
);
const LazySwitchPlanListing = lazy(
  () => import("./components/functional/Orders/SwitchPlan/SwitchPlanListing/SwitchPlanListing")
);
const LazySwitchPlanDetails = lazy(
  () => import("./components/functional/Orders/SwitchPlan/SwitchPlanDetails/SwitchPlanDetails")
);

const LazyOrderChartsListing = lazy(
  () => import("./components/functional/Orders/OrderCharts/OrderCharts")
);

const LazyPaymentListing = lazy(
  () => import("./components/functional/Payments/Payments/PaymentListing/PaymentListing")
);
const LazyPaymentDetails = lazy(
  () => import("./components/functional/Payments/Payments/PaymentDetails/PaymentDetails")
);

const LazyAssistedOrderHistory = lazy(() =>
  import("fpapps/AssistedOrderHistory").then((module) => {
    return { default: module.AssistedOrderHistory };
  })
);

const LazyAssistedPlansList = lazy(() =>
  import("fpapps/AssistedPlansList").then((module) => {
    return { default: module.AssistedPlansList };
  })
);

const LazyCheckout = lazy(() =>
  import("fpapps/Checkout").then((module) => {
    return { default: module.Checkout };
  })
);

const LazyFolios = lazy(() =>
  import("fpapps/Folios").then((module) => {
    return { default: module.Folios };
  })
);

const LazyAssistedInvestorDetails = lazy(() =>
  import("fpapps/InvestorDetails").then((module) => {
    return { default: module.InvestorDetails };
  })
);

const LazyMandateStatus = lazy(() =>
  import("fpapps/MandateStatus").then((module) => {
    return { default: module.MandateStatus };
  })
);

const LazyOnboarding = lazy(() =>
  import("fpapps/Onboarding").then((module) => {
    return { default: module.Onboarding };
  })
);

const LazyPlanUpdate = lazy(() =>
  import("fpapps/PlanUpdate").then((module) => {
    return { default: module.PlanUpdate };
  })
);

const LazyWithdraw = lazy(() =>
  import("fpapps/Withdraw").then((module) => {
    return { default: module.Withdraw };
  })
);

const LazyKYCRequestsListing = lazy(
  () => import("./components/functional/Investors/KYCRequests/KYCRequestListing/KYCRequestsListing")
);

const LazyKYCRequestsDetails = lazy(
  () => import("./components/functional/Investors/KYCRequests/KYCRequestDetails/KYCRequestDetails")
);

const LazyInvestmentAccountsListing = lazy(
  () =>
    import(
      "./components/functional/Investors/InvestmentAccounts/InvestmentAccountsListing/InvestmentAccountsListing"
    )
);

const LazyInvestmentAccountsDetails = lazy(
  () =>
    import(
      "./components/functional/Investors/InvestmentAccounts/InvestmentAccountsDetails/InvestmentAccountsDetails"
    )
);

const LazyInvestorsListing = lazy(
  () => import("./components/functional/Investors/InvestorsListing/InvestorsListing")
);

const LazyInvestorProfileDetails = lazy(
  () => import("./components/functional/Investors/InvestorProfileDetails/InvestorProfileDetails")
);

const LazyInvestorDetails = lazy(
  () => import("./components/functional/Investors/InvestorDetails/InvestorDetails")
);

const LazyMandatesListing = lazy(
  () => import("./components/functional/Payments/Mandates/MandatesListing/MandatesListing")
);

const LazyMandatesDetails = lazy(
  () => import("./components/functional/Payments/Mandates/MandatesDetails/MandatesDetails")
);

const LazyBankAccountVerificationsListing = lazy(
  () => import("./components/functional/BankAccountVerifications/BankAccountVerificationsListing")
);

const LazyFeedback = lazy(() => import("./components/functional/Feedback/Feedback"));

const LazyNonIndividualInvestorProfilesListing = lazy(
  () =>
    import(
      "./components/functional/Investors/NonIndividualInvestorProfiles/NonIndividualInvestorProfilesListing"
    )
);
const LazyNonIndividualInvestorProfileDetails = lazy(
  () =>
    import(
      "./components/functional/Investors/NonIndividualInvestorProfiles/NonIndividualInvestorProfileDetails/NonIndividualInvestorProfileDetails"
    )
);

//Functional
export {
  LazyAddTenant as AddTenant,
  LazyAdvisoryService as AdvisoryService,
  LazyAmcConfigurationService as AmcConfigurationService,
  LazyFundSchemesListing as FundSchemesListing,
  LazyIFSCCodes as IFSCCodes,
  LazyInternalUsersManagement as InternalUsersManagement,
  LazyKYCService as KYCService,
  LazyListAgingOrders as ListAgingOrders,
  LazyListApiLogs as ListApiLogs,
  LazyListFiles as ListFiles,
  LazyListPaymentTransfers as ListPaymentTransfers,
  LazyManageUser as ManageUser,
  LazyOrderConfigurationService as OrderConfigurationService,
  LazyPincodes as Pincodes,
  LazyRegenerateFatcaAnnexure as RegenerateFatcaAnnexure,
  LazyShowTransferDetails as ShowTransferDetails,
  LazyTenantUsersManagement as TenantUsersManagement,
  LazyTenantsListing as TenantsListing,
  LazyTransactionsListing as TransactionsListing,
  LazyVerificationService as VerificationService,
  LazyViewApiLogDetaills as ViewApiLogDetaills,
  LazyBITool as BITool,
  LazyScheduledQueries as ScheduledQueries,
  LazyScheduledQueryRun as ScheduledQueryRuns,
  LazyEditNotificationWebhooks as EditNotificationWebhooks,
  LazyNotificationWebhooks as NotificationWebhooks,
  LazyPartnersListing as PartnersListing,
  LazyPartnerAddition as PartnerAddition,
  LazyFpAssist as FpAssist,
  LazyBatchFilesListing as BatchFilesListing,
  LazyFolioListing as FolioListing,
  LazyFolioDetails as FolioDetails,
  LazySchemeDetails as SchemeDetails,
  LazyEventsListing as EventsListing,
  LazyPurchaseListing as PurchaseListing,
  LazyPurchaseDetails as PurchaseDetails,
  LazyRedemptionListing as RedemptionListing,
  LazyRedemptionDetails as RedemptionDetails,
  LazySwitchListing as SwitchListing,
  LazySwitchDetails as SwitchDetails,
  LazyAssistedOrderHistory as AssistedOrderHistory,
  LazyAssistedPlansList as AssistedPlansList,
  LazyCheckout as AssistedCheckout,
  LazyFolios as AssistedFolios,
  LazyAssistedInvestorDetails as AssistedInvestorDetails,
  LazyMandateStatus as AssistedMandateStatus,
  LazyOnboarding as AssistedOnboarding,
  LazyPlanUpdate as AssistedPlanUpdate,
  LazyWithdraw as AssitedWithdraw,
  LazyPurchasePlanListing as PurchasePlanListing,
  LazyPurchasePlanDetails as PurchasePlanDetails,
  LazyRedemptionPlanListing as RedemptionPlanListing,
  LazyRedemptionPlanDetails as RedemptionPlanDetails,
  LazySwitchPlanListing as SwitchPlanListing,
  LazySwitchPlanDetails as SwitchPlanDetails,
  LazyOrderChartsListing as OrderChartsListing,
  LazyPaymentListing as PaymentListing,
  LazyPaymentDetails as PaymentDetails,
  LazyInvestmentAccountsListing as InvestmentAccountsListing,
  LazyInvestmentAccountsDetails as InvestmentAccountsDetails,
  LazyInvestorsListing as InvestorsListing,
  LazyInvestorProfileDetails as InvestorProfileDetails,
  LazyInvestorDetails as InvestorDetails,
  LazyMandatesListing as MandatesListing,
  LazyMandatesDetails as MandatesDetails,
  LazyPartnerDetails as PartnerDetails,
  LazyBankAccountVerificationsListing as BankAccountVerificationsListing,
  LazyFeedback as Feedback,
  LazyNonIndividualInvestorProfilesListing as NonIndividualInvestorProfilesListing,
  LazyNonIndividualInvestorProfileDetails as NonIndividualInvestorProfileDetails,
  LazyKYCRequestsListing as KYCRequestsListing,
  LazyKYCRequestsDetails as KYCRequestsDetails,
};

//Lazy Display
const LazyNotFoundPage = lazy(() => import("./components/display/NotFoundPage"));

//Display
export { LazyNotFoundPage as NotFoundPage };

// HOC
export { default as FpDashboardInit } from "./global/hoc/FpDashboardInit";
