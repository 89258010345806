import { DataTable } from "pixel";
import { useTheme } from "../../hooks/useTheme";

type DataTableProps = React.ComponentProps<typeof DataTable>;

function ThemedDataTable(props: DataTableProps) {
  const { theme } = useTheme();

  return <DataTable enableCellTextSelection {...props} theme={theme} />;
}

export default ThemedDataTable;
